<template>
    <div>
        <b-row>
            <b-col lg="6">
                <b-tabs pills v-model="paginationLesson.StudentType">
                    <b-tab title="مراحل التعليم الجامعي" active> </b-tab>
                    <b-tab title="مراحل التعليم المدرسي"> </b-tab>
                </b-tabs>
            </b-col>
            <b-col lg="6">
                <ek-input-select
                    clearable
                    label="نوع الدرس"
                    name="filter"
                    placeholder="نوع الدرس"
                    :options="[
                        { id: '', name: 'الكل' },
                        { id: 'Video', name: 'فيديو' },
                        { id: 'Document', name: 'مستندات' },
                    ]"
                    @change="getLessonType"
                    v-model="paginationLesson.lessonType"
                ></ek-input-select>
            </b-col>
        </b-row>
        <b-card class="p-0" body-class="p-0" header-class="p-1">
            <ek-table
                :columns="lessonsTableCols"
                :items="lesson.lessons"
                @delete-selected="deleteRangeLessons"
                @details="goToDetails"
            >
                <template slot="items.dateCreated" slot-scope="{ value }">
                    <span> {{ new Date(value).toLocaleDateString() }}</span>
                </template>
                <template slot="items.url" slot-scope="{ value }">
                    <b-button @click="downloadFile(value)" variant="primary">
                        تحميل
                    </b-button>
                </template>
            </ek-table>
        </b-card>
        <!-- <ek-table
            :columns="lessonsTableCols"
            :items="lesson.lessons"
            @details="goToDetails"
            @delete-selected="deleteRangLessons"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ new Date(value).toLocaleDateString() }}
            </template>
            <template slot="items.url" slot-scope="{ value }">
                <b-button @click="downloadFile(value)" variant="primary">
                    تحميل
                </b-button>
            </template>
        </ek-table> -->
    </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            ids: [],

            // filterValueType: "",
            lessonsTableCols: [
                {
                    label: "رقم الدرس ",
                    field: "order",
                },
                {
                    label: "اسم الدرس ",
                    field: "name",
                },
                {
                    label: "نوع الدرس ",
                    field: "lessonType",
                },
                {
                    label: "تابع ل",
                    field: "followTo",
                },
                {
                    label: "تحميل",
                    field: "url",
                },
                {
                    label: "تاريخ الاضافة",
                    field: "dateCreated",
                },
                {
                    label: "تفاصيل",
                    field: "details",
                },
            ],
        };
    },
    computed: {
        ...mapState({
            lesson: ({ lessons }) => lessons.lesson,
            paginationLesson: ({ lessons }) => lessons.paginationLesson,
        }),
        ...mapGetters(["resultLessonList"]),
    },
    methods: {
        ...mapActions([
            "getLessons",
            "deleteLesson",
            "deleteRangeLesson",
            "getDepartmentsWithSubjects",
            "getGetUnRead",
            "getNamesCourses",
        ]),
        deleteRangeLessons(row) {
            this.ids = row.selectedRows.map((ele) => ele.id);
            console.log(this.ids);
        },
        deleteLessons() {
            this.deleteRangeLesson(this.ids);
        },

        goToDetails(e) {
            console.log("roo", e.row.id);
            this.$router.push(`/admin/lessons/${e.row.id}`);
        },
        getLessonType(e) {
            this.getLessons({
                lessonType: e,
                StudentType: this.paginationLesson.StudentType,
                pageIndex: 1,
                isJalseh:false,
                pageSize: 6,
            });
        },
        paginate(e) {
            console.log(e);
            this.getLessons({
                lessonType: this.paginationLesson.lessonType,
                StudentType: this.paginationLesson.StudentType,
                pageSize: 6,
                isJalseh:false,

                pageIndex: e,
            });
        },
        async downloadFile(fileUrl, decrypted) {
            if (!decrypted) {
                fileUrl = await this.getDecryptedFile(fileUrl);
            }
            axiosIns
                .get(fileUrl, {
                    baseURL: this.$store.getters["app/domainHost"],
                    responseType: "blob",
                })
                .then(({ data }) => {
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([data])
                    );
                    const link = document.createElement("a");

                    link.href = downloadUrl;

                    link.setAttribute("download", fileUrl); //any other extensio

                    document.body.appendChild(link);

                    link.click();

                    link.remove();
                });
        },
        async getDecryptedFile(fileUrl) {
            const { data } = await axiosIns.get(`File/UploadLesson`, fileUrl);
            return data;
        },
    },

    created() {
        this.getGetUnRead();
        this.getLessons({
            lessonType: this.paginationLesson.lessonType,
            StudentType: this.paginationLesson.StudentType,
            pageIndex: 1,
            pageSize: 6,
            isJalseh:false,

        });
        this.getNamesCourses(this.paginationLesson.StudentType); // StudentType == 0
        this.getDepartmentsWithSubjects(this.paginationLesson.StudentType); // StudentType == 0
    },
    watch: {
        "paginationLesson.StudentType"() {
            this.getLessons({
                lessonType: this.paginationLesson.lessonType,
                StudentType: this.paginationLesson.StudentType,
                pageIndex: 1,
                isJalseh:false,

                pageSize: 6,
            });
            this.getNamesCourses(this.paginationLesson.StudentType); // StudentType == 0
            this.getDepartmentsWithSubjects(this.paginationLesson.StudentType); // StudentType == 0
        },
    },
};
</script>

<style lang="scss">
.vgt-selection-info-row.clearfix {
    display: none !important;
}
</style>
